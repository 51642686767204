import OpenAI from 'openai';
import { Assessment, Recommendation } from '../types';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function getRecommendation(assessment: Assessment): Promise<Recommendation> {
  const prompt = `As a Bach Flower Remedy expert and spiritual guide, provide a magical and transformative recommendation based on this assessment:

Current Emotional State: ${assessment.currentMood}
${assessment.anxietyType ? `Anxiety Type: ${assessment.anxietyType}` : ''}
${assessment.sadnessType ? `Nature of Sadness: ${assessment.sadnessType}` : ''}
Primary Challenge: ${assessment.primaryChallenge}
Duration: ${assessment.duration}
Intensity: ${assessment.intensity}
Physical Symptoms: ${assessment.physicalSymptoms.join(', ')}
Triggers: ${assessment.triggers}
Current Coping Methods: ${assessment.copingMethods}
Desired Outcome: ${assessment.desiredOutcome}
${assessment.birthDate ? `Birth Date: ${assessment.birthDate}` : ''}

Please provide a detailed recommendation in this mystical format:

🌸 The Sacred Remedies
[List each Bach Flower remedy with its properties, spiritual significance, and specific healing vibration]

✨ The Mystical Blend
[Detailed mixing and dosage instructions, including the best times based on moon phases]

🌟 Your Healing Journey
[Personalized guidance including:
- Daily affirmations for each remedy
- Crystal companions for enhancement
- Meditation focus points
- Journal prompts for self-reflection
- Signs of spiritual and emotional progress
- Next recommended check-in date]

🌙 Moon Phase Wisdom
[How the current moon phase influences the healing process and remedy potency]

🔮 Daily Practice
[Specific rituals and practices to enhance the remedies' effects]

Ensure all recommendations maintain a balance between authentic Bach Flower principles and spiritual wisdom.`;

  const response = await openai.chat.completions.create({
    model: "gpt-4",
    messages: [
      {
        role: "system",
        content: "You are a mystical Bach Flower healer with deep knowledge of emotional healing, lunar cycles, and spiritual growth. Provide transformative recommendations that combine scientific accuracy with spiritual wisdom."
      },
      {
        role: "user",
        content: prompt
      }
    ],
    temperature: 0.7,
    max_tokens: 1500
  });

  const content = response.choices[0].message.content || '';
  
  return {
    remedies: parseRemedies(content),
    explanation: content,
    dosage: extractSection(content, '✨ The Mystical Blend', '🌟'),
    dailyAffirmation: extractSection(content, '🌟 Your Healing Journey', '🌙').split('Crystal companions')[0],
    moonPhaseGuidance: extractSection(content, '🌙 Moon Phase Wisdom', '🔮'),
    nextCheckInDate: calculateNextCheckIn(),
    journalPrompts: extractJournalPrompts(content)
  };
}

function parseRemedies(content: string): any[] {
  const remedySection = content.split('🌸 The Sacred Remedies')[1]?.split('✨')[0] || '';
  const remedyLines = remedySection.split('\n').filter(line => line.trim().length > 0);
  
  return remedyLines.map(line => ({
    name: line.split(':')[0]?.trim() || '',
    description: line.split(':')[1]?.trim() || '',
    affirmation: extractAffirmation(content, line.split(':')[0]?.trim() || ''),
    moonPhase: getCurrentMoonPhase()
  }));
}

function extractSection(content: string, startMarker: string, endMarker: string): string {
  const section = content.split(startMarker)[1]?.split(endMarker)[0] || '';
  return section.trim();
}

function extractJournalPrompts(content: string): string[] {
  const journalSection = content.toLowerCase().includes('journal prompts') 
    ? content.split('journal prompts')[1]?.split('\n') || []
    : [];
  return journalSection
    .filter(line => line.trim().startsWith('-'))
    .map(line => line.trim().substring(1).trim());
}

function extractAffirmation(content: string, remedyName: string): string {
  const affirmationSection = content.toLowerCase().includes('affirmations') 
    ? content.split('affirmations')[1] || ''
    : '';
  const remedyAffirmation = affirmationSection
    .split('\n')
    .find(line => line.toLowerCase().includes(remedyName.toLowerCase()));
  return remedyAffirmation?.split(':')[1]?.trim() || '';
}

function getCurrentMoonPhase(): string {
  const phases = ['New Moon', 'Waxing Crescent', 'First Quarter', 'Waxing Gibbous', 
                 'Full Moon', 'Waning Gibbous', 'Last Quarter', 'Waning Crescent'];
  const today = new Date();
  const daysSinceNewMoon = Math.floor((today.getTime() - new Date(2024, 0, 11).getTime()) / (24 * 60 * 60 * 1000));
  const currentPhaseIndex = Math.floor((daysSinceNewMoon % 29.5) / 3.7);
  return phases[currentPhaseIndex];
}

function calculateNextCheckIn(): string {
  const nextDate = new Date();
  nextDate.setDate(nextDate.getDate() + 14);
  return nextDate.toLocaleDateString('en-US', { 
    weekday: 'long', 
    month: 'long', 
    day: 'numeric' 
  });
}