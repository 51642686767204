import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { Header } from '../components/Header';
import { AssessmentForm } from '../components/AssessmentForm';
import { RecommendationDisplay } from '../components/RecommendationDisplay';
import { Assessment as AssessmentType, Recommendation } from '../types';
import { getRecommendation } from '../services/openai';

export const Assessment: React.FC = () => {
  const [user] = useAuthState(auth);
  const [recommendation, setRecommendation] = React.useState<Recommendation | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleAssessmentSubmit = async (assessment: AssessmentType) => {
    setIsLoading(true);
    try {
      const result = await getRecommendation(assessment);
      setRecommendation(result);
      
      // Save to Firestore
      if (user) {
        await addDoc(collection(db, 'recommendations'), {
          userId: user.uid,
          assessment,
          remedies: result.remedies,
          explanation: result.explanation,
          dosage: result.dosage,
          dailyAffirmation: result.dailyAffirmation,
          moonPhaseGuidance: result.moonPhaseGuidance,
          nextCheckInDate: result.nextCheckInDate,
          journalPrompts: result.journalPrompts,
          createdAt: serverTimestamp()
        });
      }
    } catch (error) {
      console.error('Error getting recommendation:', error);
      alert('Failed to get recommendation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="max-w-4xl mx-auto py-8 px-4">
        <div className="grid gap-8 md:grid-cols-2">
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Your Assessment</h2>
            <AssessmentForm onSubmit={handleAssessmentSubmit} />
          </div>
          <div>
            <RecommendationDisplay 
              recommendation={recommendation}
              isLoading={isLoading}
            />
          </div>
        </div>
      </main>
    </div>
  );
};