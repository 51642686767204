import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query, where, orderBy } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { format } from 'date-fns';
import { Loader2, Calendar, Flower, LogOut, Share2 } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

interface RecommendationData {
  remedies: Array<{ name: string }>;
  dailyAffirmation: string;
  nextCheckInDate: string;
  createdAt: { toDate: () => Date };
}

export const Dashboard: React.FC = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const recommendationsQuery = React.useMemo(() => {
    if (!user?.uid) return null;
    return query(
      collection(db, 'recommendations'),
      where('userId', '==', user.uid),
      orderBy('createdAt', 'desc')
    );
  }, [user?.uid]);

  const [snapshots, loading, error] = useCollection(recommendationsQuery);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleShare = async (data: RecommendationData) => {
    const shareText = `🌸 My Bach Flower Journey\n\nRemedies:\n${
      data.remedies?.map(r => `• ${r.name}`).join('\n') || 'No remedies found'
    }\n\nAffirmation: ${data.dailyAffirmation || 'Stay present and mindful'}`;

    try {
      if (navigator.share) {
        await navigator.share({
          title: 'My Bach Flower Journey',
          text: shareText,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(shareText);
        alert('Recommendation copied to clipboard!');
      }
    } catch (error) {
      if ((error as Error).name !== 'AbortError') {
        console.error('Error sharing:', error);
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin text-purple-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Flower className="w-8 h-8 text-purple-600" />
              <span className="ml-2 text-xl font-semibold text-gray-900">Bach Flower Oracle</span>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-600">{user?.email}</span>
              <button
                onClick={handleSignOut}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 hover:text-gray-900"
              >
                <LogOut className="w-4 h-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="flex justify-between items-center mb-8">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">Your Healing Journey</h1>
              <p className="mt-1 text-sm text-gray-500">
                Track your progress and revisit past recommendations
              </p>
            </div>
            <Link
              to="/assessment"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700"
            >
              New Assessment
            </Link>
          </div>

          {error && (
            <div className="rounded-md bg-red-50 p-4 mb-6">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    Error loading recommendations
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    {error.message || 'Please try refreshing the page.'}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {snapshots?.docs.map((doc) => {
              const data = doc.data() as RecommendationData;
              return (
                <div
                  key={doc.id}
                  className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
                >
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center space-x-2">
                        <Flower className="w-5 h-5 text-purple-600" />
                        <span className="text-sm text-gray-500">
                          {data.createdAt?.toDate ? format(data.createdAt.toDate(), 'PPP') : 'Recent'}
                        </span>
                      </div>
                    </div>

                    <h3 className="text-lg font-medium text-gray-900 mb-2">
                      Primary Remedies
                    </h3>
                    <ul className="space-y-1">
                      {data.remedies?.map((remedy, index) => (
                        <li key={index} className="text-gray-600">
                          • {remedy.name}
                        </li>
                      )) || (
                        <li className="text-gray-600">No remedies found</li>
                      )}
                    </ul>

                    {data.dailyAffirmation && (
                      <div className="mt-4 pt-4 border-t border-gray-200">
                        <p className="text-sm text-gray-600 italic">
                          "{data.dailyAffirmation}"
                        </p>
                      </div>
                    )}

                    <div className="mt-4 pt-4 border-t border-gray-200">
                      <div className="flex items-center space-x-2 text-sm text-gray-500">
                        <Calendar className="w-4 h-4" />
                        <span>Next check-in: {data.nextCheckInDate || 'Not specified'}</span>
                      </div>
                    </div>
                  </div>

                  <div className="px-4 py-4 sm:px-6">
                    <button
                      onClick={() => handleShare(data)}
                      className="inline-flex items-center space-x-2 text-purple-600 hover:text-purple-700 font-medium text-sm"
                    >
                      <Share2 className="w-4 h-4" />
                      <span>Share Journey</span>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>

          {(!snapshots?.docs.length) && (
            <div className="text-center py-12">
              <Flower className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No assessments yet</h3>
              <p className="mt-1 text-sm text-gray-500">
                Start your healing journey with your first assessment.
              </p>
              <div className="mt-6">
                <Link
                  to="/assessment"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700"
                >
                  Begin Assessment
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};