import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Flower, ArrowRight, Leaf, BookOpen, Beaker, Star } from 'lucide-react';
import { AnimatedSection } from '../components/AnimatedSection';
import { FloatingElements } from '../components/FloatingElements';

const Landing = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 via-white to-purple-50 relative">
      <FloatingElements />
      
      {/* Hero Section */}
      <section className="relative pt-20 pb-16 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <div className="flex justify-center mb-6">
                <Flower className="w-16 h-16 text-purple-600" />
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text">
                Discover Your Natural Balance with Bach Flower Remedies
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Experience personalized flower essence recommendations powered by AI wisdom, 
                guiding you towards emotional harmony and inner peace.
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-8 py-3 rounded-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-medium hover:from-purple-700 hover:to-indigo-700 transform hover:scale-105 transition-all"
              >
                Begin Your Journey
                <ArrowRight className="ml-2 w-5 h-5" />
              </Link>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Dr. Bach Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <AnimatedSection>
            <div className="max-w-4xl mx-auto">
              <div className="flex items-center justify-center mb-8">
                <BookOpen className="w-12 h-12 text-purple-600" />
              </div>
              <h2 className="text-3xl font-bold text-center mb-8">Dr. Edward Bach's Legacy</h2>
              <div className="prose max-w-none text-gray-600">
                <p className="text-lg mb-6">
                  Dr. Edward Bach (1886-1936) was a British physician, homeopath, and spiritual writer who devoted his life to developing a new system of medicine that would treat the root cause of illness - the emotional state of the patient.
                </p>
                <p className="text-lg mb-6">
                  Believing that physical illness was a manifestation of emotional imbalance, Dr. Bach spent years identifying and developing 38 flower remedies, each corresponding to a specific emotional state. His work revolutionized the field of natural healing and continues to influence holistic medicine today.
                </p>
                <div className="grid md:grid-cols-2 gap-8 mt-8">
                  <div className="bg-purple-50 p-6 rounded-lg">
                    <h3 className="text-xl font-semibold text-purple-800 mb-4">Key Principles</h3>
                    <ul className="space-y-2">
                      <li>• Treat the person, not the disease</li>
                      <li>• Emotional wellbeing leads to physical health</li>
                      <li>• Nature provides all we need to heal</li>
                      <li>• Simple, pure, and natural healing</li>
                    </ul>
                  </div>
                  <div className="bg-purple-50 p-6 rounded-lg">
                    <h3 className="text-xl font-semibold text-purple-800 mb-4">His Vision</h3>
                    <p>
                      Dr. Bach envisioned a system of healing that would be simple enough for everyone to use, yet powerful enough to transform lives. His remedies were designed to be gentle, non-toxic, and compatible with all other forms of treatment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </AnimatedSection>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-16 bg-gradient-to-r from-purple-50 to-indigo-50">
        <div className="container mx-auto px-4">
          <AnimatedSection>
            <div className="text-center mb-12">
              <Beaker className="w-12 h-12 text-purple-600 mx-auto mb-4" />
              <h2 className="text-3xl font-bold">How Bach Flower Remedies Work</h2>
            </div>
            <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">Energy Healing</h3>
                <p className="text-gray-600">
                  Bach Flower Remedies work on the principle of vibrational healing, capturing the energy signature of flowers to restore emotional balance.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">Emotional Transformation</h3>
                <p className="text-gray-600">
                  Each remedy addresses specific emotional states, helping transform negative feelings into positive ones.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">Gentle Integration</h3>
                <p className="text-gray-600">
                  The remedies work subtly yet effectively, supporting natural emotional healing without side effects.
                </p>
              </div>
            </div>
          </AnimatedSection>
        </div>
      </section>

      {/* The 38 Remedies Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <AnimatedSection>
            <div className="text-center mb-12">
              <Leaf className="w-12 h-12 text-purple-600 mx-auto mb-4" />
              <h2 className="text-3xl font-bold">The 38 Bach Flower Remedies</h2>
              <p className="text-gray-600 mt-4">Each remedy addresses specific emotional states and personality traits</p>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
              <div className="bg-purple-50 p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">For Fear</h3>
                <ul className="space-y-2 text-gray-600">
                  <li>• Mimulus - Known, everyday fears</li>
                  <li>• Aspen - Unknown fears and anxiety</li>
                  <li>• Cherry Plum - Fear of losing control</li>
                  <li>• Red Chestnut - Fear for others' welfare</li>
                  <li>• Rock Rose - Terror and panic</li>
                </ul>
              </div>
              <div className="bg-purple-50 p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">For Uncertainty</h3>
                <ul className="space-y-2 text-gray-600">
                  <li>• Cerato - Lack of trust in self</li>
                  <li>• Scleranthus - Indecision</li>
                  <li>• Gentian - Discouragement</li>
                  <li>• Gorse - Hopelessness</li>
                  <li>• Hornbeam - Mental tiredness</li>
                </ul>
              </div>
              <div className="bg-purple-50 p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">For Present Circumstances</h3>
                <ul className="space-y-2 text-gray-600">
                  <li>• Clematis - Dreaminess</li>
                  <li>• Honeysuckle - Living in the past</li>
                  <li>• Wild Rose - Resignation</li>
                  <li>• Olive - Mental exhaustion</li>
                  <li>• White Chestnut - Unwanted thoughts</li>
                </ul>
              </div>
            </div>
          </AnimatedSection>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-16 bg-gradient-to-r from-purple-50 to-indigo-50">
        <div className="container mx-auto px-4">
          <AnimatedSection>
            <div className="text-center mb-12">
              <Star className="w-12 h-12 text-purple-600 mx-auto mb-4" />
              <h2 className="text-3xl font-bold">Benefits of Bach Flower Remedies</h2>
            </div>
            <div className="grid md:grid-cols-4 gap-8 max-w-6xl mx-auto">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">Natural & Safe</h3>
                <ul className="space-y-2 text-gray-600">
                  <li>• Non-toxic and gentle</li>
                  <li>• No side effects</li>
                  <li>• Compatible with all treatments</li>
                  <li>• Suitable for all ages</li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">Emotional Balance</h3>
                <ul className="space-y-2 text-gray-600">
                  <li>• Reduces stress and anxiety</li>
                  <li>• Improves emotional resilience</li>
                  <li>• Enhances self-awareness</li>
                  <li>• Supports personal growth</li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">Mental Clarity</h3>
                <ul className="space-y-2 text-gray-600">
                  <li>• Better decision making</li>
                  <li>• Improved focus</li>
                  <li>• Mental calmness</li>
                  <li>• Enhanced creativity</li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4 text-purple-800">Holistic Healing</h3>
                <ul className="space-y-2 text-gray-600">
                  <li>• Mind-body connection</li>
                  <li>• Preventive care</li>
                  <li>• Long-term wellbeing</li>
                  <li>• Spiritual growth</li>
                </ul>
              </div>
            </div>
          </AnimatedSection>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-gradient-to-r from-purple-600 to-indigo-600 text-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <AnimatedSection>
              <h2 className="text-3xl font-bold mb-6">
                Begin Your Bach Flower Journey Today
              </h2>
              <p className="text-xl opacity-90 mb-8">
                Discover the perfect combination of remedies for your emotional wellbeing with our AI-powered consultation.
              </p>
              <Link
                to="/register"
                className="inline-flex items-center px-8 py-3 rounded-full bg-white text-purple-600 font-medium hover:bg-gray-100 transform hover:scale-105 transition-all"
              >
                Start Free Consultation
                <ArrowRight className="ml-2 w-5 h-5" />
              </Link>
            </AnimatedSection>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;