import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Recommendation } from '../types';
import { Sparkles, Moon, ScrollText, Calendar, Share2 } from 'lucide-react';

interface Props {
  recommendation: Recommendation | null;
  isLoading: boolean;
}

export const RecommendationDisplay: React.FC<Props> = ({ recommendation, isLoading }) => {
  const handleShare = async () => {
    const shareText = `🌸 I discovered my personalized Bach Flower remedies! My healing journey includes:\n\n${
      recommendation?.remedies.map(r => `• ${r.name}`).join('\n')
    }\n\nStart your healing journey at Mystical Bach Flower Oracle ✨`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'My Bach Flower Journey',
          text: shareText,
          url: window.location.href
        });
      } catch (error) {
        if ((error as Error).name !== 'AbortError') {
          console.error('Error sharing:', error);
          fallbackShare(shareText);
        }
      }
    } else {
      fallbackShare(shareText);
    }
  };

  const fallbackShare = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Recommendation copied to clipboard! You can now share it with others.');
    }).catch(err => {
      console.error('Failed to copy:', err);
    });
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center py-12 space-y-4">
        <div className="relative">
          <div className="w-16 h-16 border-4 border-purple-200 border-t-purple-600 rounded-full animate-spin"></div>
          <Sparkles className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 text-purple-600" />
        </div>
        <p className="text-purple-600 font-medium animate-pulse">
          Channeling the wisdom of flowers...
        </p>
      </div>
    );
  }

  if (!recommendation) return null;

  return (
    <div className="bg-gradient-to-b from-white to-purple-50 shadow-lg rounded-lg p-8">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-2">
          <Sparkles className="w-6 h-6 text-purple-600" />
          <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text">
            Your Sacred Healing Journey
          </h2>
        </div>
        <button
          onClick={handleShare}
          className="flex items-center space-x-2 px-4 py-2 bg-purple-100 text-purple-700 rounded-lg hover:bg-purple-200 transition-colors"
        >
          <Share2 className="w-4 h-4" />
          <span>Share Journey</span>
        </button>
      </div>
      
      <div className="prose max-w-none">
        <ReactMarkdown 
          components={{
            h1: ({node, ...props}) => <h1 className="text-2xl font-bold text-purple-800" {...props} />,
            h2: ({node, ...props}) => <h2 className="text-xl font-semibold text-purple-700" {...props} />,
            p: ({node, ...props}) => <p className="text-gray-700 leading-relaxed" {...props} />,
            ul: ({node, ...props}) => <ul className="list-disc list-inside space-y-2" {...props} />,
            li: ({node, ...props}) => <li className="text-gray-700" {...props} />
          }}
        >
          {recommendation.explanation}
        </ReactMarkdown>
      </div>

      <div className="grid gap-6 mt-8">
        <div className="p-6 bg-purple-50 rounded-lg border border-purple-200">
          <div className="flex items-center space-x-2 mb-3">
            <Moon className="w-5 h-5 text-purple-600" />
            <h3 className="font-semibold text-purple-800">🌙 Moon Phase Guidance</h3>
          </div>
          <p className="text-purple-900 leading-relaxed">{recommendation.moonPhaseGuidance}</p>
        </div>

        <div className="p-6 bg-purple-50 rounded-lg border border-purple-200">
          <div className="flex items-center space-x-2 mb-3">
            <ScrollText className="w-5 h-5 text-purple-600" />
            <h3 className="font-semibold text-purple-800">📔 Journal Prompts</h3>
          </div>
          <ul className="space-y-2">
            {recommendation.journalPrompts.map((prompt, index) => (
              <li key={index} className="text-purple-900">{prompt}</li>
            ))}
          </ul>
        </div>

        <div className="p-6 bg-purple-50 rounded-lg border border-purple-200">
          <div className="flex items-center space-x-2 mb-3">
            <Calendar className="w-5 h-5 text-purple-600" />
            <h3 className="font-semibold text-purple-800">🗓️ Next Check-in</h3>
          </div>
          <p className="text-purple-900">
            Schedule your next consultation on: {recommendation.nextCheckInDate}
          </p>
          <p className="text-sm text-purple-700 mt-2">
            Return for a follow-up assessment to track your healing journey and receive updated guidance.
          </p>
        </div>
      </div>
    </div>
  );
};