import { motion } from 'framer-motion';
import { Flower } from 'lucide-react';

export const FloatingElements = () => {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {Array.from({ length: 15 }).map((_, i) => (
        <motion.div
          key={i}
          className="absolute"
          initial={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
            scale: Math.random() * 0.5 + 0.5,
            opacity: 0
          }}
          animate={{
            y: [null, Math.random() * -100 - 50],
            opacity: [0, 0.3, 0],
            transition: {
              duration: Math.random() * 10 + 10,
              repeat: Infinity,
              ease: "linear"
            }
          }}
        >
          <Flower className="text-sage-200 w-8 h-8 transform rotate-45" />
        </motion.div>
      ))}
      <div className="absolute inset-0 bg-gradient-radial from-transparent via-transparent to-white/30" />
    </div>
  );
};