import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Dashboard } from './pages/Dashboard';
import { Assessment } from './pages/Assessment';
import Landing from './pages/Landing';
import { AuthLayout } from './components/AuthLayout';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        
        <Route path="/dashboard" element={
          <AuthLayout>
            <Dashboard />
          </AuthLayout>
        } />
        
        <Route path="/assessment" element={
          <AuthLayout>
            <Assessment />
          </AuthLayout>
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;