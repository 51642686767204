import React, { useState, useEffect } from 'react';
import { Assessment } from '../types';
import { Wand2, Loader2 } from 'lucide-react';
import { getNextQuestion } from '../services/questions';

interface Props {
  onSubmit: (assessment: Assessment) => void;
}

export const AssessmentForm: React.FC<Props> = ({ onSubmit }) => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [assessment, setAssessment] = useState<Assessment>({
    currentMood: '',
    symptoms: [],
    duration: '',
    intensity: '',
    triggers: '',
    sleepPatterns: '',
    copingMethods: '',
    desiredOutcome: '',
    physicalSymptoms: []
  });

  const initialQuestions = [
    {
      title: "🌟 Initial Emotional Reading",
      question: "Dear seeker, what brings you to consult the Bach Flower wisdom today? Share your emotional state...",
      field: "currentMood",
      type: "textarea"
    }
  ];

  useEffect(() => {
    setQuestions(initialQuestions);
  }, []);

  const fetchNextQuestion = async () => {
    setIsLoading(true);
    try {
      const nextQuestion = await getNextQuestion(assessment, step);
      if (nextQuestion) {
        setQuestions(prev => [...prev, nextQuestion]);
      }
    } catch (error) {
      console.error('Error fetching next question:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = async () => {
    if (step < 8) {
      await fetchNextQuestion();
      setStep(step + 1);
    } else {
      onSubmit(assessment);
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setQuestions(prev => prev.slice(0, -1));
      setStep(step - 1);
    }
  };

  const currentQuestion = questions[step - 1];

  const handleInputChange = (field: keyof Assessment, value: string | string[]) => {
    setAssessment(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleMultiSelect = (value: string) => {
    const field = currentQuestion.field as keyof Assessment;
    const currentValues = (assessment[field] as string[]) || [];
    const updatedValues = currentValues.includes(value)
      ? currentValues.filter(v => v !== value)
      : [...currentValues, value];
    handleInputChange(field, updatedValues);
  };

  const isAnswered = () => {
    const value = assessment[currentQuestion.field as keyof Assessment];
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return Boolean(value);
  };

  if (!currentQuestion) return null;

  return (
    <div className="space-y-6 relative">
      <div className="absolute top-0 right-0 flex space-x-2">
        {Array.from({ length: 8 }).map((_, idx) => (
          <div
            key={idx}
            className={`w-2 h-2 rounded-full ${
              idx + 1 <= step ? 'bg-purple-600' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>

      <div className="min-h-[300px]">
        <h3 className="text-xl font-semibold mb-2 text-purple-700">
          {currentQuestion.title}
        </h3>
        <p className="text-gray-600 mb-4 italic">
          {currentQuestion.question}
        </p>

        {currentQuestion.type === 'textarea' ? (
          <textarea
            className="w-full p-3 border border-purple-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent min-h-[120px]"
            value={assessment[currentQuestion.field as keyof Assessment] as string || ''}
            onChange={(e) => handleInputChange(currentQuestion.field as keyof Assessment, e.target.value)}
            placeholder="Share your thoughts..."
          />
        ) : currentQuestion.type === 'multiselect' ? (
          <div className="space-y-2 max-h-[300px] overflow-y-auto">
            {currentQuestion.options?.map((option: { value: string; label: string }) => (
              <label
                key={option.value}
                className="flex items-center space-x-3 p-3 rounded-lg hover:bg-purple-50 cursor-pointer border border-transparent hover:border-purple-200 transition-colors"
              >
                <input
                  type="checkbox"
                  checked={(assessment[currentQuestion.field as keyof Assessment] as string[] || []).includes(option.value)}
                  onChange={() => handleMultiSelect(option.value)}
                  className="w-4 h-4 rounded text-purple-600 focus:ring-purple-500 border-gray-300"
                />
                <span className="text-gray-700">{option.label}</span>
              </label>
            ))}
          </div>
        ) : (
          <select
            className="w-full p-3 border border-purple-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            value={assessment[currentQuestion.field as keyof Assessment] as string || ''}
            onChange={(e) => handleInputChange(currentQuestion.field as keyof Assessment, e.target.value)}
          >
            <option value="">Choose wisely...</option>
            {currentQuestion.options?.map((option: { value: string; label: string }) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
      </div>

      <div className="flex justify-between pt-4">
        {step > 1 ? (
          <button
            onClick={handlePrevious}
            className="px-4 py-2 text-purple-600 hover:text-purple-800 transition-colors"
          >
            ← Previous Question
          </button>
        ) : (
          <div />
        )}
        <button
          onClick={handleNext}
          disabled={isLoading || !isAnswered()}
          className="flex items-center space-x-2 px-6 py-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-lg hover:from-purple-700 hover:to-indigo-700 disabled:opacity-50 transition-all"
        >
          {isLoading ? (
            <>
              <Loader2 className="w-5 h-5 animate-spin" />
              <span>Consulting the Oracle...</span>
            </>
          ) : step === 8 ? (
            <>
              <span>Reveal My Remedies</span>
              <Wand2 className="w-5 h-5" />
            </>
          ) : (
            <span>Next Question →</span>
          )}
        </button>
      </div>
    </div>
  );
};