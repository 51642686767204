import React from 'react';
import { Flower } from 'lucide-react';

export const Header: React.FC = () => {
  return (
    <header className="bg-gradient-to-r from-purple-600 via-purple-500 to-indigo-600 text-white py-8 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center space-x-3 mb-4">
          <Flower className="w-8 h-8" />
          <h1 className="text-3xl font-bold">Mystical Bach Flower Oracle</h1>
        </div>
        <p className="text-lg opacity-90 max-w-2xl">
          Journey through the ancient wisdom of Bach Flower Remedies to discover the healing essences that await your spirit
        </p>
      </div>
    </header>
  );
};