import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAt-Pgxy3figPJaDkpuqnJmEYfYXDi_8NY",
  authDomain: "bach-flower-remedies-9qw8ys.firebaseapp.com",
  projectId: "bach-flower-remedies-9qw8ys",
  storageBucket: "bach-flower-remedies-9qw8ys.appspot.com",
  messagingSenderId: "377639008795",
  appId: "1:377639008795:web:7911fd69c410c9108c56be"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);