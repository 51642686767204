import OpenAI from 'openai';
import { Assessment } from '../types';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function getNextQuestion(assessment: Assessment, currentStep: number): Promise<any> {
  const prompt = `As a Bach Flower expert, analyze these previous answers and generate the most relevant next question:

${Object.entries(assessment)
  .filter(([_, value]) => value && value.length > 0)
  .map(([key, value]) => `${key}: ${value}`)
  .join('\n')}

Current step: ${currentStep}

Generate a single follow-up question that:
1. Builds upon previous answers
2. Helps understand the emotional state deeper
3. Reveals underlying patterns or triggers
4. Is specifically relevant to Bach Flower remedy selection

Return the question in this exact JSON format without any additional text or markdown:
{
  "title": "Mystical title with emoji",
  "question": "The actual question text",
  "field": "uniqueFieldName",
  "type": "textarea|select|multiselect",
  "options": [] (only if type is select/multiselect)
}`;

  const response = await openai.chat.completions.create({
    model: "gpt-4",
    messages: [
      {
        role: "system",
        content: "You are an expert Bach Flower practitioner creating a dynamic questionnaire. Generate questions that dig deeper into emotional patterns and help identify the most suitable remedies. Always respond with valid JSON."
      },
      {
        role: "user",
        content: prompt
      }
    ],
    temperature: 0.7
  });

  try {
    const content = response.choices[0].message.content || '{}';
    return JSON.parse(content);
  } catch (error) {
    console.error('Error parsing question:', error);
    return null;
  }
}